.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #fafafa;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

.faq-title-sub-heading {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "neuePowerTrial-Medium";
  letter-spacing: 1px;
}

.tfm-start--journey--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 3.125rem 0px 0px 0px;
  background: #001316;
}
.tfm-start--journey--section .container {
  max-width: 1240px;
  margin: auto;
  border-radius: 20px;
  position: relative;
  padding: 0px;
  display: flex;
}
.tfm-start--journey--section .container .text--left--part {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.tfm-start--journey--section .container .text--left--part h2 {
  margin-bottom: 2.625rem;
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 3.75rem;
  font-family: "neuePowerTrial-Regular";
  text-transform: capitalize;
}
.tfm-start--journey--section .container .text--left--part p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-bottom: 2.25rem;
  font-family: "neueMontreal-Regular" !important;
}
.tfm-start--journey--section .container .text--left--part p br {
  display: none;
}
.tfm-start--journey--section .container .text--left--part a {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #001316;
  background-color: #75ffff;
  padding: 0.75rem 3rem;
  border-radius: 36px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "neuePowerTrial-Medium";
}
.tfm-start--journey--section .container .img-part {
  width: 40%;
}
.tfm-start--journey--section .container .img-part .web-view {
  display: block;
}
.tfm-start--journey--section .container .img-part .mob-view {
  display: none;
}
.tfm-start--journey--section .container .img-part img {
  margin-bottom: -1.5rem;
}

.tfm-footer--section {
  background: #75ffff;
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
  padding-bottom: 6.875rem;
}
.tfm-footer--section .container {
  max-width: 1240px;
  margin: auto;
  padding: 2.875rem 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.tfm-footer--section .container:nth-child(3) {
  padding: 1.375rem 0px 0rem 0px;
  border-bottom: 1px solid #a0a0a0;
}
.tfm-footer--section .container .footer-logo-sizes {
  max-width: 7.5rem;
}
.tfm-footer--section .container .footer-block {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}
.tfm-footer--section .container .footer-block .links-part {
  text-wrap: pretty;
}
.tfm-footer--section .container .footer-block .links-part h5 {
  font-family: "neuePowerTrial-Medium";
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: left;
  color: #001316;
  margin-bottom: 2.5rem;
}
.tfm-footer--section .container .footer-block .links-part ul {
  margin-top: 0rem !important;
  padding-left: 0rem !important;
}
.tfm-footer--section .container .footer-block .links-part ul li {
  margin-bottom: 1.5rem;
  list-style: none !important;
}
.tfm-footer--section .container .footer-block .links-part ul li a {
  color: #001316;
  font-family: "neueMontreal-Regular" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: left;
}
.tfm-footer--section .container .footer-block .content-part h5 {
  font-family: "neuePowerTrial-Regular";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: left;
  position: relative;
  margin-bottom: 2.25rem;
}
.tfm-footer--section .container .footer-block .content-part .first::after {
  border-top: 1px solid #001316;
  content: "";
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 150px;
  z-index: 4;
}
.tfm-footer--section .container .footer-block .content-part h6 {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;
  margin-bottom: 1.875rem;
}
.tfm-footer--section .container .footer-block .content-part p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
  margin-bottom: 1.5rem;
}
.tfm-footer--section .container .footer-block .content-part .social-part {
  margin-bottom: 2.75rem;
  display: flex;
}
.tfm-footer--section .container .footer-block .content-part .headings-add {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}
.tfm-footer--section .container .footer-block .content-part .add-part {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0rem;
}
.tfm-footer--section .container .footer-block .content-part .add-part:nth-child(2) {
  margin-bottom: 0rem;
}
.tfm-footer--section .container .footer-block .content-part .add-part p {
  margin-left: 1.5rem;
  font-family: "neueMontreal-Regular" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
}
.tfm-footer--section .container .footer-block .content-part .add-part p span {
  font-weight: 600;
}
.tfm-footer--section .container .mobile-view {
  display: none;
}
.tfm-footer--section .footer-rights-block {
  padding: 1.5rem 0rem 2rem 0rem;
}
.tfm-footer--section .footer-rights-block a {
  color: #001316;
}

.tfm-who-we-what-we-section {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 0.5rem;
  background: #001316;
}
.tfm-who-we-what-we-section .container {
  max-width: 1240px;
  margin: auto;
}
.tfm-who-we-what-we-section .container .first-text--part {
  display: flex;
  justify-content: space-between;
}
.tfm-who-we-what-we-section .container .first-text--part .heading-part {
  width: 45%;
}
.tfm-who-we-what-we-section .container .first-text--part .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 5.625rem;
  font-weight: 500;
  line-height: 6.75rem;
  text-align: left;
  margin-top: 1.875rem;
  margin-bottom: 1.625rem;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .container .first-text--part .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.tfm-who-we-what-we-section .container .first-text--part .description-part {
  width: 45%;
  display: flex;
  align-items: center;
}
.tfm-who-we-what-we-section .container .first-text--part .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 2rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .second-text--part {
  border-top: 1px solid #fffaf7;
  border-bottom: 1px solid #fffaf7;
}
.tfm-who-we-what-we-section .second-text--part .container {
  display: flex;
  justify-content: space-between;
}
.tfm-who-we-what-we-section .second-text--part .container .img-part {
  width: 45%;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  border-right: 1px solid #fffaf7;
}
.tfm-who-we-what-we-section .second-text--part .container .img-part img {
  max-width: 26rem;
}
.tfm-who-we-what-we-section .second-text--part .container .description-part {
  width: 45%;
  display: flex;
  align-items: center;
}
.tfm-who-we-what-we-section .second-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 1rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .third-text--part {
  border-bottom: 1px solid #fffaf7;
}
.tfm-who-we-what-we-section .third-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.tfm-who-we-what-we-section .third-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.tfm-who-we-what-we-section .third-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 0rem;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .third-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.tfm-who-we-what-we-section .third-text--part .container .heading-part h2 .mob-view {
  display: none;
}
.tfm-who-we-what-we-section .third-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  border-right: 1px solid #fffaf7;
  padding-right: 2rem;
}
.tfm-who-we-what-we-section .third-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .fourth-text--part {
  border-bottom: 1px solid #fffaf7;
}
.tfm-who-we-what-we-section .fourth-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.tfm-who-we-what-we-section .fourth-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  border-right: 1px solid #fffaf7;
}
.tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 0rem;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.tfm-who-we-what-we-section .fourth-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  padding-right: 2rem;
}
.tfm-who-we-what-we-section .fourth-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .fifth-text--part {
  background-color: #fffaf7;
}
.tfm-who-we-what-we-section .fifth-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.tfm-who-we-what-we-section .fifth-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 1.625rem;
  color: #fffaf7;
}
.tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.tfm-who-we-what-we-section .fifth-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  border-right: 1px solid #fffaf7;
  padding-right: 2rem;
  padding: 3.75rem 2.5rem 3.75rem 0rem;
}
.tfm-who-we-what-we-section .fifth-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  margin-top: 0rem;
  color: #fffaf7;
  font-size: 33px;
  font-weight: 400;
  line-height: 56.17px;
  text-align: left;
}
.tfm-who-we-what-we-section .fifth-text--part .container .img-part {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tfm-who-we-what-we-section .fifth-text--part .container .img-part img {
  max-width: 330px;
}

.home-tfm-who-we-what-we-section {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 0.5rem;
  background: #001316;
}
.home-tfm-who-we-what-we-section .container {
  max-width: 1240px;
  margin: auto;
}
.home-tfm-who-we-what-we-section .container .first-text--part {
  display: flex;
  justify-content: space-between;
}
.home-tfm-who-we-what-we-section .container .first-text--part .heading-part {
  width: 45%;
}
.home-tfm-who-we-what-we-section .container .first-text--part .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 5.625rem;
  font-weight: 500;
  line-height: 6.75rem;
  text-align: left;
  margin-top: 1.875rem;
  margin-bottom: 1.625rem;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .container .first-text--part .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.home-tfm-who-we-what-we-section .container .first-text--part .description-part {
  width: 45%;
  display: flex;
  align-items: center;
}
.home-tfm-who-we-what-we-section .container .first-text--part .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 2rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .second-text--part {
  border-top: 1px solid #fffaf7;
  border-bottom: 1px solid #fffaf7;
}
.home-tfm-who-we-what-we-section .second-text--part .container {
  display: flex;
  justify-content: space-between;
}
.home-tfm-who-we-what-we-section .second-text--part .container .img-part {
  width: 45%;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  border-right: 1px solid #fffaf7;
}
.home-tfm-who-we-what-we-section .second-text--part .container .img-part img {
  max-width: 26rem;
}
.home-tfm-who-we-what-we-section .second-text--part .container .description-part {
  width: 45%;
  display: flex;
  align-items: center;
}
.home-tfm-who-we-what-we-section .second-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 1rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .third-text--part {
  border-bottom: 1px solid #fffaf7;
}
.home-tfm-who-we-what-we-section .third-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.home-tfm-who-we-what-we-section .third-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.home-tfm-who-we-what-we-section .third-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 0rem;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .third-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.home-tfm-who-we-what-we-section .third-text--part .container .heading-part h2 .mob-view {
  display: none;
}
.home-tfm-who-we-what-we-section .third-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  border-right: 1px solid #fffaf7;
  padding-right: 2rem;
}
.home-tfm-who-we-what-we-section .third-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .fourth-text--part {
  border-bottom: 1px solid #fffaf7;
}
.home-tfm-who-we-what-we-section .fourth-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.home-tfm-who-we-what-we-section .fourth-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  border-right: 1px solid #fffaf7;
}
.home-tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 0rem;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.home-tfm-who-we-what-we-section .fourth-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  padding-right: 2rem;
}
.home-tfm-who-we-what-we-section .fourth-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0rem;
  line-height: 2.125rem;
  text-align: left;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .fifth-text--part {
  background-color: #fffaf7;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 1.625rem;
  color: #fffaf7;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  border-right: 1px solid #fffaf7;
  padding-right: 2rem;
  padding: 3.75rem 2.5rem 3.75rem 0rem;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  margin-top: 0rem;
  color: #fffaf7;
  font-size: 33px;
  font-weight: 400;
  line-height: 56.17px;
  text-align: left;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .img-part {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-tfm-who-we-what-we-section .fifth-text--part .container .img-part img {
  max-width: 330px;
}

.tfm-single--app--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 5rem 0rem;
  background: #001316;
}
.tfm-single--app--section .container {
  max-width: 1240px;
  margin: auto;
  width: 100%;
}
.tfm-single--app--section .container h2 {
  text-align: center;
  margin-bottom: 0.875rem;
  text-transform: capitalize;
  font-family: "neuePowerTrial-Regular";
  color: #fffaf7;
}
.tfm-single--app--section .container h2 span {
  color: #75ffff;
}
.tfm-single--app--section .container h2 br {
  display: none;
}
.tfm-single--app--section .container p {
  font-family: "neueMontreal-Regular";
  font-weight: 100;
  line-height: 28.8px;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #fffaf7;
}
.tfm-single--app--section .container a.start--investing {
  background-color: #003399;
  padding: 0.625rem 1.5rem;
  border-radius: 64px;
  color: #fafafa;
  font-size: 1.375rem;
  display: block;
  font-weight: 500;
  line-height: 2rem;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
}
.tfm-single--app--section .container a.start--investing img {
  width: 20px;
}
.tfm-single--app--section .container .plan--sections {
  padding-top: 4.875rem;
  background-size: contain;
  justify-content: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url(../images/webp/bg-logo-full.webp);
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(1) {
  width: 49%;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(1) .card--layout .img--part {
  display: flex;
  margin-bottom: -1.25rem;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(2) {
  width: 49%;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(3) {
  width: 49%;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(4) {
  width: 49%;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout {
  border-radius: 20px;
  box-shadow: 0px 4px 67.1px 0px rgba(0, 0, 0, 0.0901960784);
  padding: 1.375rem 0rem 1.375rem 1.375rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  border: 1px solid rgba(117, 247, 255, 0.4);
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .arrow-part {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: #75ffff;
  padding: 0.5rem;
  display: flex;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .text--part {
  padding-bottom: 1.75rem;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0rem;
  min-width: 40%;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .text--part h3 {
  color: #fffaf7;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.813rem;
  font-family: "neuePowerTrial-Medium";
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .text--part p {
  color: #fffaf7;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}
.tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .img--part {
  margin-bottom: -1.8rem;
  margin-left: 0rem;
}
.tfm-single--app--section .container .plan--sections .plan--mobile--section {
  display: none;
}

.home-tfm-single--app--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 5rem 0rem;
  background: #fffaf7;
}
.home-tfm-single--app--section .container {
  max-width: 1240px;
  margin: auto;
  width: 100%;
}
.home-tfm-single--app--section .container h2 {
  text-align: center;
  margin-bottom: 0.875rem;
  text-transform: capitalize;
  font-family: "neuePowerTrial-Regular";
  color: #001316;
}
.home-tfm-single--app--section .container h2 span {
  color: #001316;
}
.home-tfm-single--app--section .container h2 br {
  display: none;
}
.home-tfm-single--app--section .container p {
  font-family: "neueMontreal-Regular";
  font-weight: 100;
  line-height: 28.8px;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #001316;
}
.home-tfm-single--app--section .container a.start--investing {
  background-color: #003399;
  padding: 0.625rem 1.5rem;
  border-radius: 64px;
  color: #fafafa;
  font-size: 1.375rem;
  display: block;
  font-weight: 500;
  line-height: 2rem;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
}
.home-tfm-single--app--section .container a.start--investing img {
  width: 20px;
}
.home-tfm-single--app--section .container .plan--sections {
  padding-top: 4.875rem;
  background-size: contain;
  justify-content: center;
  background-position: bottom;
  background-repeat: no-repeat;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(1) {
  width: 49%;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(1) .card--layout .img--part {
  display: flex;
  margin-bottom: -1.25rem;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(2) {
  width: 49%;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(3) {
  width: 49%;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span:nth-child(4) {
  width: 49%;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout {
  border-radius: 20px;
  box-shadow: 0px 4px 67.1px 0px rgba(0, 0, 0, 0.0901960784);
  padding: 1.375rem 0rem 1.375rem 1.375rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  border: 1px solid rgba(117, 247, 255, 0.4);
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .arrow-part {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: #75ffff;
  padding: 0.5rem;
  display: flex;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .text--part {
  padding-bottom: 1.75rem;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0rem;
  min-width: 40%;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .text--part h3 {
  color: #001316;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.813rem;
  font-family: "neuePowerTrial-Medium";
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .text--part p {
  color: #001316;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}
.home-tfm-single--app--section .container .plan--sections .plan--container .tfm-plan--top--section span .card--layout .img--part {
  margin-bottom: -1.8rem;
  margin-left: 0rem;
}
.home-tfm-single--app--section .container .plan--sections .plan--mobile--section {
  display: none;
}

.marquee {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fefefe;
  color: #333;
  border: 1px solid #fefefe;
  white-space: nowrap; /* Ensure the text stays in one line */
}
.marquee h3 {
  animation: scroll-left 100s linear infinite;
  font-family: "neuePowerTrial-Medium";
  font-size: 1.375rem;
  font-weight: 400;
  height: 100%;
  line-height: 50px;
  margin: 0;
  position: absolute;
  text-align: center;
  width: -moz-max-content;
  width: max-content;
}
.marquee h3 span {
  font-size: 0.75rem;
  font-family: "neueMontreal-Regular" !important;
}

@keyframes scroll-left {
  0% { /* Firefox bug fix */ /* Firefox bug fix */
    transform: translateX(0%);
  }
  100% { /* Firefox bug fix */ /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
.tfm-technology--forefront--section {
  position: relative;
  width: 100%;
  float: left;
  background-color: #fff;
}
.tfm-technology--forefront--section .container {
  max-width: 1240px;
  margin: auto;
  padding: 11.5rem 0 8.5rem 0;
}
.tfm-technology--forefront--section .container h2 {
  text-align: center;
  margin-bottom: 2.25rem;
  color: #fafafa;
  font-family: "neuePowerTrial-Regular";
  text-transform: capitalize;
  color: #001316;
}
.tfm-technology--forefront--section .container p {
  font-weight: 400;
  color: #fffaf7;
  font-family: "neueMontreal-Regular";
  color: #001316;
}
.tfm-technology--forefront--section .container .technology--card--section {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top {
  background: #cdd2fe;
  border-radius: 20px;
  padding: 3.125rem 0 0 3.125rem;
  display: flex;
  background-image: url(../images/webp/top-lrft-design.webp);
  background-repeat: no-repeat;
  background-position: right bottom;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .img--part {
  width: 55%;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .img--part img {
  margin-bottom: -0.5rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -3rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part h3 {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.25rem;
  font-weight: 500;
  line-height: 3.875rem;
  text-align: left;
  margin-bottom: 1rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part a {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #001316;
  font-family: "neuePowerTrial-Regular";
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part a img {
  position: absolute;
  width: 1.25rem;
  left: 6rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part p {
  text-align: left !important;
  margin-bottom: 1.625rem !important;
  font-family: "neueMontreal-Book";
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 1.875rem;
  text-align: left;
  color: #001316;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom {
  display: flex;
  gap: 30px;
  padding: 0rem;
  display: flex;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common {
  width: 50%;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part {
  padding-left: 3rem;
  padding-bottom: 4.875rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part h3 {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.25rem;
  font-weight: 500;
  line-height: 3.875rem;
  text-align: left;
  margin-bottom: 1rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part p {
  text-align: left !important;
  margin-bottom: 1.625rem !important;
  font-family: "neueMontreal-Book";
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 1.875rem;
  text-align: left;
  color: #001316;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #001316;
  font-family: "neuePowerTrial-Regular";
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a img {
  position: absolute;
  width: 1.25rem;
  left: 6rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(1) {
  border-radius: 30px;
  background: #ffab6e;
  background-image: url(../images/webp/tech-front-2-design.webp);
  background-repeat: no-repeat;
  background-position: right bottom;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) {
  border-radius: 30px;
  background: #339581;
  background-image: url(../images/webp/tech-front-3-design.webp);
  background-repeat: no-repeat;
  background-position: right top;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .text--part {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .img-part {
  padding: 50px;
}


@media (max-width: 900px) {
  .tfm-who-we-what-we-section {
    padding: 1.875rem 0 0rem 0;
  }
  .tfm-who-we-what-we-section .container {
    padding: 1rem;
  }
  .tfm-who-we-what-we-section .container .first-text--part {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .tfm-who-we-what-we-section .container .first-text--part .heading-part {
    width: 100%;
  }
  .tfm-who-we-what-we-section .container .first-text--part .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 0.875rem;
  }
  .tfm-who-we-what-we-section .container .first-text--part .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .tfm-who-we-what-we-section .container .first-text--part .description-part {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .tfm-who-we-what-we-section .container .first-text--part .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .tfm-who-we-what-we-section .second-text--part {
    border-top: 1px solid #001316;
    border-bottom: 1px solid #001316;
  }
  .tfm-who-we-what-we-section .second-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .tfm-who-we-what-we-section .second-text--part .container .img-part {
    width: 100%;
    padding-top: 2.25rem;
    padding-bottom: 2rem;
    border-right: 0px solid #001316;
  }
  .tfm-who-we-what-we-section .second-text--part .container .img-part img {
    max-width: 26rem;
  }
  .tfm-who-we-what-we-section .second-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .tfm-who-we-what-we-section .second-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 0rem;
  }
  .tfm-who-we-what-we-section .third-text--part {
    border-bottom: 1px solid #001316;
  }
  .tfm-who-we-what-we-section .third-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-direction: column;
  }
  .tfm-who-we-what-we-section .third-text--part .container .heading-part {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .tfm-who-we-what-we-section .third-text--part .container .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 1.625rem;
  }
  .tfm-who-we-what-we-section .third-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .tfm-who-we-what-we-section .third-text--part .container .heading-part h2 .mob-view {
    display: block;
  }
  .tfm-who-we-what-we-section .third-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 0px solid #001316;
    padding-right: 0rem;
  }
  .tfm-who-we-what-we-section .third-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .tfm-who-we-what-we-section .fourth-text--part {
    border-bottom: 1px solid #001316;
  }
  .tfm-who-we-what-we-section .fourth-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-direction: column;
  }
  .tfm-who-we-what-we-section .fourth-text--part .container .heading-part {
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    border-right: 0px solid #001316;
  }
  .tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 1.25rem;
  }
  .tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .tfm-who-we-what-we-section .fourth-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0rem;
  }
  .tfm-who-we-what-we-section .fourth-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
  }
  .tfm-who-we-what-we-section .fifth-text--part {
    padding-top: 5.5rem;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .heading-part {
    width: 40%;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 {
    font-family: "neuePowerTrial-Regular";
    font-size: 6.625rem;
    font-weight: 500;
    line-height: 8rem;
    text-align: left;
    margin-bottom: 1.625rem;
    color: #fffaf7;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 0px solid #fffaf7;
    padding-right: 2rem;
    padding: 2.5rem 1rem;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .description-part p {
    font-size: 24px;
    font-weight: 400;
    line-height: 40.85px;
    text-align: left;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .img-part {
    width: 100%;
    justify-content: flex-end;
  }
  .tfm-who-we-what-we-section .fifth-text--part .container .img-part img {
    width: 50%;
  }
  .home-tfm-who-we-what-we-section {
    padding: 1.875rem 0 0rem 0;
  }
  .home-tfm-who-we-what-we-section .container {
    padding: 1rem;
  }
  .home-tfm-who-we-what-we-section .container .first-text--part {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .home-tfm-who-we-what-we-section .container .first-text--part .heading-part {
    width: 100%;
  }
  .home-tfm-who-we-what-we-section .container .first-text--part .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 0.875rem;
  }
  .home-tfm-who-we-what-we-section .container .first-text--part .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .home-tfm-who-we-what-we-section .container .first-text--part .description-part {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .home-tfm-who-we-what-we-section .container .first-text--part .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .home-tfm-who-we-what-we-section .second-text--part {
    border-top: 1px solid #001316;
    border-bottom: 1px solid #001316;
  }
  .home-tfm-who-we-what-we-section .second-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .home-tfm-who-we-what-we-section .second-text--part .container .img-part {
    width: 100%;
    padding-top: 2.25rem;
    padding-bottom: 2rem;
    border-right: 0px solid #001316;
  }
  .home-tfm-who-we-what-we-section .second-text--part .container .img-part img {
    max-width: 26rem;
  }
  .home-tfm-who-we-what-we-section .second-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .home-tfm-who-we-what-we-section .second-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 0rem;
  }
  .home-tfm-who-we-what-we-section .third-text--part {
    border-bottom: 1px solid #001316;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-direction: column;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container .heading-part {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 1.625rem;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container .heading-part h2 .mob-view {
    display: block;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 0px solid #001316;
    padding-right: 0rem;
  }
  .home-tfm-who-we-what-we-section .third-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part {
    border-bottom: 1px solid #001316;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-direction: column;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part .container .heading-part {
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    border-right: 0px solid #001316;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 1.25rem;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0rem;
  }
  .home-tfm-who-we-what-we-section .fourth-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part {
    padding-top: 5.5rem;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .heading-part {
    width: 40%;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 {
    font-family: "neuePowerTrial-Regular";
    font-size: 6.625rem;
    font-weight: 500;
    line-height: 8rem;
    text-align: left;
    margin-bottom: 1.625rem;
    color: #fffaf7;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 0px solid #fffaf7;
    padding-right: 2rem;
    padding: 2.5rem 1rem;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .description-part p {
    font-size: 24px;
    font-weight: 400;
    line-height: 40.85px;
    text-align: left;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .img-part {
    width: 100%;
    justify-content: flex-end;
  }
  .home-tfm-who-we-what-we-section .fifth-text--part .container .img-part img {
    width: 50%;
  }
  .tfm-start--journey--section {
    padding: 2.625rem 1rem 0rem 1rem;
  }
  .tfm-start--journey--section .container {
    flex-direction: column;
  }
  .tfm-start--journey--section .container .text--left--part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .tfm-start--journey--section .container .text--left--part h2 {
    margin-bottom: 2rem;
    font-size: 2.625rem;
    font-weight: 600;
    line-height: 3.625rem;
    text-align: left;
  }
  .tfm-start--journey--section .container .text--left--part p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 2.25rem;
    text-align: left;
  }
  .tfm-start--journey--section .container .text--left--part p br {
    display: none;
  }
  .tfm-start--journey--section .container .text--left--part a {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    padding: 0.75rem 1.125rem;
    border-radius: 20px;
    width: 100%;
    text-align: center;
  }
  .tfm-start--journey--section .container .img-part {
    width: 100%;
  }
  .tfm-start--journey--section .container .img-part .web-view {
    display: none;
  }
  .tfm-start--journey--section .container .img-part .mob-view {
    display: block;
  }
  .tfm-start--journey--section .container .img-part img {
    margin-bottom: -1.5rem;
  }
  .tfm-footer--section .container {
    padding: 2.625rem 1rem 0px 1rem;
    flex-direction: column;
  }
  .tfm-footer--section .container:nth-child(3) {
    padding: 1.375rem 1rem 0px 1rem;
    border-bottom: 1px solid #a0a0a0;
  }
  .tfm-footer--section .container .footer-logo-sizes {
    max-width: 7.5rem;
  }
  .tfm-footer--section .container .footer-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .tfm-footer--section .container .footer-block .links-part {
    width: 45%;
    margin-bottom: 0.625rem;
  }
  .tfm-footer--section .container .footer-block .links-part h5 {
    font-family: "neuePowerTrial-Medium";
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.375rem;
    text-align: left;
    color: #001316;
    margin-bottom: 1.5rem;
  }
  .tfm-footer--section .container .footer-block .links-part ul {
    margin-top: 0rem !important;
    padding-left: 0rem !important;
  }
  .tfm-footer--section .container .footer-block .links-part ul li {
    margin-bottom: 1.5rem;
    list-style: none !important;
  }
  .tfm-footer--section .container .footer-block .links-part ul li a {
    color: #001316;
    font-family: "neueMontreal-Regular" !important;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  .tfm-footer--section .container .footer-block .content-part h5 {
    font-family: "neuePowerTrial-Regular";
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    position: relative;
    margin-bottom: 2.25rem;
  }
  .tfm-footer--section .container .footer-block .content-part .first::after {
    border-top: 1px solid #001316;
    content: "";
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 150px;
    z-index: 4;
  }
  .tfm-footer--section .container .footer-block .content-part h6 {
    font-family: "neuePowerTrial-Regular";
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    margin-bottom: 1.875rem;
  }
  .tfm-footer--section .container .footer-block .content-part p {
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .tfm-footer--section .container .footer-block .content-part .social-part {
    margin-bottom: 2.75rem;
    display: flex;
    justify-content: center;
  }
  .tfm-footer--section .container .footer-block .content-part .headings-add {
    font-family: "neuePowerTrial-Regular";
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .tfm-footer--section .container .footer-block .content-part .add-part {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }
  .tfm-footer--section .container .footer-block .content-part .add-part:nth-child(2) {
    margin-bottom: 0rem;
  }
  .tfm-footer--section .container .footer-block .content-part .add-part p {
    margin-left: 1.5rem;
    font-family: "neueMontreal-Regular" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  .tfm-footer--section .container .footer-block .content-part .add-part p span {
    font-weight: 600;
  }
  .tfm-footer--section .container .mobile-view {
    display: block;
  }
  .tfm-footer--section .container .web-view {
    display: none !important;
  }
  .tfm-footer--section .footer-rights-block {
    padding: 1.5rem 0rem 0rem 0rem;
  }
  .tfm-footer--section .footer-rights-block a {
    color: #001316;
  }
  .tfm-single--app--section {
    padding: 3.75rem 1rem;
    background-size: cover;
  }
  .tfm-single--app--section .container h2 {
    margin-bottom: 0.875rem;
    font-family: "neuePowerTrial-Medium";
    font-size: 2.625rem;
    font-weight: 500;
    line-height: 57.6px;
    text-align: left;
  }
  .tfm-single--app--section .container p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
    font-family: "neueMontreal-Thin";
    text-align: left;
    color: #fffaf7;
  }
  .tfm-single--app--section .container a.start--investing {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    line-height: 2rem;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    justify-content: center;
  }
  .tfm-single--app--section .container a.start--investing img {
    margin-left: 0.5rem;
  }
  .tfm-single--app--section .container .plan--sections {
    padding-top: 3.75rem;
  }
  .tfm-single--app--section .container .plan--sections .plan--container {
    display: none;
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section {
    display: block;
    padding: 0rem;
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout {
    background-color: #001316;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 67.1px 0px rgba(0, 0, 0, 0.0901960784);
    padding: 1.375rem 1.375rem 0rem 1.375rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    border: 1px solid rgba(117, 247, 255, 0.4);
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .arrow-part {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #75ffff;
    padding: 0.5rem;
    display: flex;
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 0rem;
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part h3 {
    line-height: 2.625rem;
    text-align: left;
    color: #fffaf7;
    margin-bottom: 0.5rem;
    font-family: "neuePowerTrial-Medium";
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part p {
    font-family: "neueMontreal-Regular";
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.5rem;
    color: #fffaf7;
    margin-bottom: 1.5rem;
  }
  .tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .img--part {
    margin-bottom: -5px;
  }
  .home-tfm-single--app--section {
    padding: 3.75rem 1rem;
    background-size: cover;
  }
  .home-tfm-single--app--section .container h2 {
    margin-bottom: 0.875rem;
    font-family: "neuePowerTrial-Medium";
    font-size: 2.625rem;
    font-weight: 500;
    line-height: 57.6px;
    text-align: left;
  }
  .home-tfm-single--app--section .container p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
    font-family: "neueMontreal-Thin";
    text-align: left;
    color: #001316;
  }
  .home-tfm-single--app--section .container a.start--investing {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    line-height: 2rem;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    justify-content: center;
  }
  .home-tfm-single--app--section .container a.start--investing img {
    margin-left: 0.5rem;
  }
  .home-tfm-single--app--section .container .plan--sections {
    padding-top: 3.75rem;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--container {
    display: none;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section {
    display: block;
    padding: 0rem;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout {
    background-color: #fffaf7;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 67.1px 0px rgba(0, 0, 0, 0.0901960784);
    padding: 1.375rem 1.375rem 0rem 1.375rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    border: 1px solid rgba(117, 247, 255, 0.4);
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .arrow-part {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #75ffff;
    padding: 0.5rem;
    display: flex;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 0rem;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part h3 {
    line-height: 2.625rem;
    text-align: left;
    color: #001316;
    margin-bottom: 0.5rem;
    font-family: "neuePowerTrial-Medium";
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part p {
    font-family: "neueMontreal-Regular";
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.5rem;
    color: #001316;
    margin-bottom: 1.5rem;
  }
  .home-tfm-single--app--section .container .plan--sections .plan--mobile--section .card--layout .img--part {
    margin-bottom: -5px;
  }
  .tfm-technology--forefront--section .container {
    padding: 3rem 1rem 10rem 1rem;
  }
  .tfm-technology--forefront--section .container h2 {
    font-size: 2.625rem;
    font-weight: 500;
    line-height: 68px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .tfm-technology--forefront--section .container h2 span {
    font-weight: 500;
    line-height: 68px;
    text-align: left;
  }
  .tfm-technology--forefront--section .container p {
    font-size: 18px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
  }
  .tfm-technology--forefront--section .container p br {
    display: none;
  }
  .tfm-technology--forefront--section .container .technology--card--section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top {
    padding: 3rem 1rem 0rem 1rem;
    flex-direction: column-reverse;
    background-image: url(../images/webp/top-lrft-design.webp);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 26%;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .img--part {
    width: 100%;
    margin-top: 3.75rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .img--part img {
    margin-bottom: -0.5rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part h3 {
    font-size: 38px;
    font-weight: 500;
    line-height: 45.6px;
    text-align: left;
    margin-bottom: 0.75rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part p {
    font-size: 20px;
    line-height: 23.44px;
    text-align: left;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part a {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-top .text--part a img {
    position: absolute;
    width: 1.25rem;
    left: 7.5rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom {
    flex-direction: column;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common {
    width: 100%;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part {
    padding: 1rem 1rem 5.25rem 1rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part h3 {
    font-size: 38px;
    font-weight: 500;
    line-height: 45.6px;
    text-align: left;
    margin-bottom: 0.75rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part p {
    font-size: 20px;
    line-height: 23.44px;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a img {
    position: absolute;
    width: 1.25rem;
    left: 7.5rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(1) {
    border-radius: 30px;
    background-image: url(../images/webp/tech-front-2-design-mob.webp);
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) {
    border-radius: 30px;
    background-image: url(../images/webp/tech-front-3-design-mob.webp);
    background-repeat: no-repeat;
    background-position: right top;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .text--part {
    padding-top: 5.25rem;
    padding-bottom: 1rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .text--part h3 {
    margin-bottom: 1rem;
  }
  .tfm-technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .img-part {
    padding: 1rem;
  }
}/*# sourceMappingURL=tfm-common.css.map */
